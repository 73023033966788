import { Modal, Box, IconButton, CardContent } from '@mui/material'
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import RsCard from '../Card/RsCard'

export const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  maxWidth: '100%',
}

const RsModal = ({
  title,
  subtitle,
  id,
  renderTriggerComponent,
  renderContentComponent,
  modalStylesProp,
  disableClose,
}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    if (disableClose) {
      return
    }
    setOpen(false)
  }

  if (modalStylesProp) {
    Object.assign(modalStyles, modalStylesProp)
  }

  const triggerComponent = renderTriggerComponent({
    onOpen: handleOpen,
  })

  const contentComponent = renderContentComponent({
    onClose: handleClose,
  })

  return (
    <Box>
      {triggerComponent}
      <Modal open={open} onClose={handleClose}>
        <Box>
          <RsCard
            title={title}
            subheader={subtitle}
            cardProps={{ sx: modalStyles, id }}
            headerRightSlot={
              <IconButton onClick={handleClose} size="small" id="rs-modal-close-button">
                <CloseIcon sx={theme => ({ color: theme.colors.alpha.black[70] })} />
              </IconButton>
            }
          >
            <Box sx={{ maxHeight: '80vh', overflowY: 'scroll' }}>{contentComponent}</Box>
          </RsCard>
        </Box>
      </Modal>
    </Box>
  )
}

export default RsModal
