import Immutable from 'immutable'
import moment from 'moment-timezone'

const timeFormats = Immutable.Map({
  day: 'dddd', // 'Saturday'
  dayNumber: 'D', // 2
  month: 'MMM', // Feb
  monthDayNumber: 'MMM D', // Feb 2
  monthDay: 'MMM Do', // Feb 2nd
  monthDayTime: 'MMM D, h:mmA', // Feb 2, 1:44PM
  monthDayYearTime: 'MMM D, YYYY [at] h:mmA', // Feb 2, 1:44PM
  monthDayYear: 'MMM D, YYYY', // Feb 2, 2016
  monthDayAtTime: 'MMM D [at] h:mmA', // Feb 2 at 1:44PM
  monthDayYearAbbr: 'MM/DD/YY', // 02/02/16
  monthDayAbbr: 'MM/DD', // 02/02
  time: 'h:mmA', // 1:44PM
  timeTimezone: 'h:mmA z', // 1:44PM PST
  monthDayAtTimeTimezone: 'MMM D [at] h:mmA z', // Feb 2 at 1:44PM PDT
  dateInput: 'YYYY-MM-DD', // 2016-02-02,
  monthDayYearTimeAbbr: 'MM/DD/YY h:mmA', // 02/02/16 1:44PM
  monthDayYearAMPM: 'MM/DD/YY - A', // 02/02/16 - PM
})

export default class TimeFormatter {
  static format(val, key, timezone = moment.tz.guess()) {
    const formattedVal = moment(val).tz(timezone)
    if (formattedVal.isValid()) {
      return formattedVal.format(timeFormats.get(key))
    }
    return 'Not Available'
  }

  static formatFromNow(val, dropSuffix = false) {
    return moment(val).fromNow(dropSuffix)
  }

  static formatUnix(val, key, timezone = moment.tz.guess()) {
    return moment.unix(val).tz(timezone).format(timeFormats.get(key))
  }

  static formatUnixFromNow(val, dropSuffix = false) {
    return moment.unix(val).fromNow(dropSuffix)
  }

  static formatUnixDaysFromNow(val) {
    const futureDate = moment.unix(val)
    const today = moment()
    return futureDate.diff(today, 'days')
  }

  static formatUnixWithOffset(val, key, offset) {
    return moment.unix(val).utcOffset(offset).format(timeFormats.get(key))
  }
}
