import React, {Component} from 'react';

const _renderMessage = (message) => {
  if (!message) {
    return null;
  }

  return (
      <small>
        {message}
      </small>
  )
};

export default function LoadingIcon(props = {}) {
  let {message, style} = props;

  return (
      <div className="rs-loading-circle">
        <div className="spinner" style={style}>
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>

        {_renderMessage(message)}
      </div>
  )
};